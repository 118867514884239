<template>
  <div class="modal" :class="showModalFlag ? 'is-active' : ''">
    <div class="modal-background"></div>
    <div v-if="showModalFlag" class="modal-card">
      <header class="modal-card-head" style="flex-direction: row; align-content: flex-start;">
        <p class="modal-card-title">Edit {{ choiceName }}</p>
        <button class="delete" aria-label="close" v-on:click="cancelModal()">></button>
      </header>
      <section class="modal-card-body  " style="min-height: 80px">

       <p>More editable things will follow........</p>


       <form class="form">
       <div class="field">
        <label class="label">Limit (use 0 for unlimited)</label>
        <div class="control">
          <input class="input"  type="number" step=1 min=0  v-model="limit"
            required>
        </div>
      </div>
    </form>


      </section>
      <footer class="modal-card-foot is-center" style="text-align: center; align-content: flex-start; flex-direction: column;">

      <div style="margin: auto;">
        <button class="button is-danger " v-on:click="cancelModal()">Cancel</button>
        <button class="button is-success " v-on:click="okModal()">Ok</button>
      </div>



      </footer>
    </div>
  </div>
</template>


<script>

export default {
  name: "modal-student-register-group",




  data: function () {
    return {
      showModalFlag: false,
      okPressed: false,

      choiceName: null,
      choiceDetails: null,

      limit: 0,

      students: null,
      currentrm: null,
      regPeriod: null,
      regMarks: null,
      period: null,
      checks: {},
      pid: null
    };
  },


  computed: {


  },
  methods: {



    showModal: function (choice) {
      this.okPressed = false;
      this.showModalFlag = true;

      this.choiceName = choice[0]
      this.choiceDetails = choice[1]

      this.limit = this.choiceDetails.limit ?? 0

    },
    okModal: async function (_type) {
      this.okPressed = true;

      let data = { ...this.choiceDetails }
      data.limit = this.limit

      this.$emit("updated-menu-item", {name: this.choiceName, details: data})

      this.showModalFlag = false;




    },
    cancelModal: function () {
      this.okPressed = false;
      this.showModalFlag = false;
    }
  }
}
</script>

<style>
ul.mylist {
  border: 1px solid #333;
}

ul.mylist > div > li {
  padding: 8px 16px;
  border-bottom: 1px solid #333;
}

ul.mylist > div > li:last-child {
  padding: 8px 16px;
  border-bottom: none;
}


</style>