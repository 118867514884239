<template>
  <div class="container has-background-white-ter">
    <section class="section is-primary pb-1">

        <div class="columns">
          <div class="column is-4-desktop">
            <h1 class="title is-2 is-spaced">Lunches</h1>
          </div>
          <div class="column is-4-desktop has-text-centered-desktop">
            <h1 v-if="loaded" class="is-size-4 has-text-centered-desktop">
              {{ pOrders.count }} {{ pOrders.count | pluralize("order") }}
            </h1>
            <h1 v-else class="is-size-4 has-text-centered-desktop">
              Loading
            </h1>
          </div>
          <div class="column is-4-desktop has-text-right-desktop">
            <h1 class="is-size-4 has-text-right-desktop">
              {{ this.dateD }}
            </h1>
          </div>
        </div>

        <div class="field is-grouped is-grouped-centered">
          <p class="control">
            <button :disabled="!loaded" class="button" @click="earlier()">
              <Icon icon="mdi:arrow-left-bold-box" class="is-64x64" /> Earlier
            </button>
          </p>
          <p class="control">
            <button
              class="button"
              :disabled="pOrders.count === 0 || !loaded"
              @click="printSlips()"
            >
              Print Slips
            </button>
          </p>
          <p class="control">
            <button :disabled="!loaded" class="button" @click="later()">
              Later <Icon icon="mdi:arrow-right-bold-box" class="is-64x64" />
            </button>
          </p>
        </div>

    </section>
    <section id="users-timetable" class="section">
      <div v-if="loaded" class="container">
        <div class="columns is-12-desktop is-justify-content-center">
          <div class="column">
          <div v-if="menu" class="tile is-ancestor is-justify-content-center">
            <div v-for="opts, choice in menu.options" :key="choice" class="tile is-parent is-2">
              <article role="button" data-choice="choice" class="tile is-child box" :class="activeChoice(choice)">
                <div class="content has-text-centered" style="height: 100%">
                    <p>
                      <strong>{{ choice }}</strong>
                    </p>
                    <button :disabled="pOrders.options[choice] < 1" class="button" style="margin-top:2px;" @click="showList(choice)">View {{ pOrders.options[choice] ?? 0 }} {{ (pOrders.options[choice] ?? 0)|pluralize("order")}}</button><br />
                    <button class="button" style="margin-top:2px;" @click="editOption(choice)">Edit</button>
                </div>
              </article>

            </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="container notification is-primary">
        <div class="columns">
          <span class="bulma-loader-mixin"></span>
        </div>
      </div>
    </section>
    <section v-if="cData.length" class="section">
      <div class="table-container">
        <table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
          <thead>
            <tr>
              <th rowspan=2 class="is-vcentered">Who</th>
              <th v-for="(selection, iSel) in menu['options'][aChoice]['options']" :key="iSel" :colspan="selection.choose.length" class="has-text-centered">
                {{ selection.title}}
              </th>
            </tr>
            <tr>


              <template v-for="selection in menu['options'][aChoice]['options']" >

                <th v-for="(selectionA, iSelA) in selection['choose']"
                  :key="selection.title+' '+iSelA"
                  class="has-text-centered"

                >
                {{ lunchH.getOptionDescription(selectionA)}}
              </th>
              </template>
            </tr>
          </thead>
          <tr v-for="(choice, index) in cData" :key="index">
            <th>{{ choice[2] }}, {{ choice[3] }}</th>
            <td v-for="(x, xi) in getOrderTicks(choice[1])" :key="xi" class="has-text-centered is-vcentered">
              <Icon v-if="x" icon="mdi:check-circle" class="is-64x64" />

            </td>


          </tr>

        </table>
      </div>

    </section>
    <div class="field is-horizontal">
      <div class="field-body is-flex is-flex-shrink-1 is-flex-grow-5 is-justify-content-center" >
        <div class="select">
           <select v-model="monthChooser">
              <option v-for="mon in sixmonths" :key="mon" :value="mon">{{ mon }}</option>
           </select>
        </div>
        <div class="field is-narrow">
          <div class="control">

            <button
              class="button"
              @click="downloadSummary()"
            >
              Download Summary
            </button>

          </div>
        </div>
        <div class="field is-narrow">
          <div class="control">
            <button
              class="button"
              :disabled="isCharged || isCharging"
              @click="charge()"
            >
              Charge Students
            </button>

          </div>
        </div>
     </div>
   </div>




   <EditMenuItemModal ref="myEditMenuItemModal" @updated-menu-item="updateMenuItem"/>


  </div>
</template>

<script>

import { Icon } from "@iconify/vue2";
import lunch from "helpers/lunch";
import { format, sub as datesub } from 'date-fns' ;
//import { sum } from 'pdf-lib';
import { toast } from 'bulma-toast'

import EditMenuItemModal from "Components/lunch/EditMenuItemModal.vue"


export default {
  name: "LunchManager",

  components: {
    Icon,
    EditMenuItemModal,
  },

  data: function () {
    return {
      menu: [],
      orders: [],
      date: new Date(),
      dateD: format(new Date(), "ccc, do LLL yyyy"),
      loaded: false,
      aChoice: null,
      isCharged: false,
      isCharging: false,
      cData: [],
      lunchH: lunch,
      monthChooser: format(datesub(new Date(), { months: 1}), "yyyy-MM"),
    };
  },


  watch: {
    dateD: {
      handler: function (oldV, newV) {
//        console.log(this.date, oldV, newV, "Loading")
        if (newV === undefined) {
          return;
        }
        this.loaded = false;
        this.aChoice = null;
        this.cData = []

        let date = format(this.date, "yyyy-MM-dd")
//        console.log(date)

        this.$api
          .get("v2/staff/lunch/" + date + "/", true, true)
          .then((response) => {
            this.menu = response[0];
            this.orders = response[1];
            this.isCharged = response[3]
          })
          .finally(() => {
            this.loaded = true;
          });
      },
      deep: true,
      immediate: true,
    },
  },

  computed: {
    enabled() {
      return this.$store.state.currentHM < "11:45";
    },

    sixmonths() {

      let rtn = [];

      for (let i=0; i<6; i++) {
        rtn.push(format(datesub(this.date, { months: i}), "yyyy-MM"));
      }

      return rtn;
    },



    pOrders() {

      let cnt = this.orders?.length ?? 0
      let rtn = { count: cnt, options: {} };

      if (cnt === 0) {
        return rtn;
      }

      for (let option of this.orders) {
        rtn["options"][option[0]] = 1 + (rtn["options"][option[0]] || 0);
      }

      return rtn;
    },


  },

  methods: {



    editOption(choice) {
      this.$refs.myEditMenuItemModal.showModal([choice, this.menu["options"][choice] ])
    },


    updateMenuItem(x) {


      console.log(x)


      this.menu["options"][x.name] = x.details

      this.$api
          .post("v2/staff/lunch/" + format(this.date, "yyyy-MM-dd") + "/", true, true, { menu: this.menu })
          .then((response) => {
            console.log(response)
          })

    },



    getOrderTicks(option) {

      let rtn = []

       for (let selection of this.menu['options'][this.aChoice]['options']) {

        for (let selectionA of selection['choose']) {
            rtn.push(option.includes(selection.title+"_#_"+selectionA))
        }
      }

      return rtn;

    },

    async downloadSummary() {

      let summary = await this.$api.get("v2/staff/lunch/summary/"+this.monthChooser, true, true)

      //console.log([summary], typeof(summary))

      lunch.processSummary(this.monthChooser, summary);

    },

    async printSlips() {
      lunch.printSlips(this.orders, format(this.date, "yyyy-MM-dd"), this.menu);
    },
    earlier() {
      this.date.setDate(this.date.getDate() - 1)
      this.dateD = format(this.date, "ccc, do LLL yyyy")
    },
    later() {
      this.date.setDate(this.date.getDate() + 1)
      this.dateD = format(this.date, "ccc, do LLL yyyy")
    },
    showList(choice) {

      let q = this.orders.filter(x => {return x[0] == choice })
      this.aChoice = choice;
      this.cData = q

//      console.log(choice, q, this.menu.options[choice], )

    },
    activeChoice(choice) {
//      console.log(choice, this.aChoice)
      if (this.aChoice === choice) {
        return "has-background-primary"
      }
      return ""
    },


  async charge() {
    let date = format(this.date, "yyyy-MM-dd")
//        console.log(date)
        this.isCharging = true

        this.$api
          .get("v2/staff/lunch/charge/" + date + "/", true, true)
          .then(data => {
            console.log(data)
            if (data.metadata.errors == 0) {
              this.isCharged = true;
              toast({
                  message: (data.metadata.updated+data.metadata.created).toString() + " students have been charged",
                  type: 'is-success',
                  duration: 2000,
                  closeOnClick: true,
              })

            }
            else {
              toast({
                  message: "Sorry, something went wrong, please see console for details",
                  type: 'is-warning',
                  duration: 2000,
                  closeOnClick: true,
              })

            }
            })
          .finally(() => {
            this.isCharging = false;
          });
  },
  },

  async mounted() {
    this.loaded = false;
    this.$api
      .get("v2/staff/lunch/today/", true, true)
      .then((response) => {
        this.menu = response[0];
        this.orders = response[1];
        this.isCharged = response[3]
      })
      .finally(() => {
        this.loaded = true;
      });

    //this.loadRegister();
  },
};
</script>

